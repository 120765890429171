var collapsibleSections = collapsibleSections || {};

(function($){

	collapsibleSections.cssClasses = {
		"open": "collapsible-sections-open"
	};

	collapsibleSections.getFormMarkupVersion = function( form_or_id ){
		var formElementID;
		var formID = '';
		var $form;

		if ( typeof form_or_id === 'undefined' || ( typeof form_or_id === 'string' && form_or_id === '' ) || ( typeof form_or_id === 'number' && form_or_id <= 0 ) ) {
			formElementID = $('form[id^="gform_"]:first').attr('id');
			formID = ( window.hasOwnProperty('gf_get_form_id_by_html_id') ) ? window.gf_get_form_id_by_html_id( formElementID ) : formElementID.replace('gform_', '');
			$form = $('#' + formElementID);
		}
		else if ( form_or_id instanceof jQuery ) {
			formElementID = form_or_id.attr('id');
			formID = ( window.hasOwnProperty('gf_get_form_id_by_html_id') ) ? window.gf_get_form_id_by_html_id( formElementID ) : formElementID.replace('gform_', '');
			$form = form_or_id;
		}
		else if ( typeof form_or_id === 'string' && form_or_id.indexOf("gform_") !== -1 ) {
			formID = (window.hasOwnProperty('gf_get_form_id_by_html_id')) ? window.gf_get_form_id_by_html_id( form_or_id ) : form_or_id.replace('gform_', '');
			formElementID = "gform_" + formID;
			$form = $('#' + formElementID);
		}
		else {
			formID = (window.hasOwnProperty('gf_get_form_id_by_html_id')) ? window.gf_get_form_id_by_html_id( "gform_" + form_or_id.toString() ) : form_or_id.toString();
			formElementID = "gform_" + formID;
			$form = $('#' + formElementID);
		}

		if ( !$form.length ) {
			return $('.gform_body .gfield:first').is('li') ? 1 : 2;
		}

		return $form.find('.gform_body .gfield:first').is('li') ? 1 : 2;
	};

	collapsibleSections.isLegacyMarkup = function( form_or_id ) {
		return ( collapsibleSections.getFormMarkupVersion( form_or_id ) === 1 );
	}

	collapsibleSections.isLegacyMode = function() {
		var useNewFeatures = ( collapsibleSectionsVars.hasOwnProperty('useNewFeatures') && collapsibleSectionsVars.useNewFeatures.toString() === 'true');
		return !useNewFeatures;
	};


	collapsibleSections.openAndFocusInput = function( $sectionField, direction ){
		if ( ! $sectionField.hasClass('collapsible-sections-field') ) {
			return;
		}

		if ( direction !== 'previous' ) {
			direction = 'next';
		}

		if ( ! $sectionField.hasClass(collapsibleSections.cssClasses.open) ) {
			$sectionField.trigger('click');
		}
		else {
			collapsibleSections.scrollTo( $sectionField );
		}

		var $form = $sectionField.closest('form');
		var formIgnoreScroll = ( typeof $form.data('ignore-scroll') !== 'undefined' && $form.data('ignore-scroll') === true );

		if ( !formIgnoreScroll ) {
			// only give focus if we're scrolling to the open section
			var selector = ( direction === 'previous' ) ? ':last-of-type' : ':first';
			var $focusSectionFieldInputContainer = $sectionField.next('.collapsible-sections-collapsible-body').find('.gfield'+selector+' .ginput_container');
			if ($focusSectionFieldInputContainer.find('> input').length) {
				$focusSectionFieldInputContainer.find('> input:first').focus();
			}
			else if ($focusSectionFieldInputContainer.find('> textarea').length) {
				$focusSectionFieldInputContainer.find('> textarea:first').focus();
			}
			else if ($focusSectionFieldInputContainer.find('> select').length) {
				$focusSectionFieldInputContainer.find('> select:first').focus();
			}
			else if ($focusSectionFieldInputContainer.hasClass('ginput_complex')) {
				$focusSectionFieldInputContainer.find('> span'+selector+' > input:first').focus();
			}
			else if ($focusSectionFieldInputContainer.hasClass('ginput_container_checkbox') || $focusSectionFieldInputContainer.hasClass('ginput_container_radio')) {
				$focusSectionFieldInputContainer.find('> ul > li'+selector+' > input:first').focus();
			}
		}

	};
	window.collapsibleSections_openAndFocusInput = collapsibleSections.openAndFocusInput;// legacy support added in 1.1.0

	collapsibleSections.scrollTo = function( elementOrSelector ){

		var $el = $(elementOrSelector);
		if (!$el.length) {
			return false;
		}

		var $form = $el.closest('form');
		var $formWrapper = $el.closest('.gform_wrapper');
		var formID = $formWrapper.attr('id').replace('gform_wrapper_', '');

		var formIgnoreScroll = ( typeof $form.data('ignore-scroll') !== 'undefined' && $form.data('ignore-scroll') === true );

		if (formIgnoreScroll === true) {
			return false;
		}

		var offset = (window.hasOwnProperty('gf_collapsible_sections_scroll_to_offset_'+formID)) ? window['gf_collapsible_sections_scroll_to_offset_'+formID] : 0;
		var duration = (window.hasOwnProperty('gf_collapsible_sections_scroll_to_duration_'+formID)) ? window['gf_collapsible_sections_scroll_to_duration_'+formID] : 400;
		var targetTop = $el.offset().top;
		var scrollPos = targetTop - offset;

		$('html,body').animate({ scrollTop: scrollPos }, duration);
	};
	window.collapsibleSections_Scrollto = collapsibleSections.scrollTo;// legacy support added in 1.1.0

	collapsibleSections.onSectionKeyPress = function(e){
		if ( e.key === "Enter" || e.key === " " ) {
			var $target = $(e.target);
			if ( $target.hasClass('collapsible-sections-field') ) {
				e.preventDefault();
				$target.trigger('click');
			}
		}
	};
	collapsibleSections.onSectionFocus = function(e){
		$(document).on('keypress', collapsibleSections.onSectionKeyPress);
	};
	collapsibleSections.onSectionBlur = function(e){
		$(document).off('keypress', collapsibleSections.onSectionKeyPress);
	};

	collapsibleSections.setup = function( formDomID, currentPage, gformID ) {

		var $formWrapper = $('#gform_wrapper_'+formDomID);
		var $collapsibleSections = $formWrapper.find('.collapsible-sections-field');

		if ($collapsibleSections.length) {

			var $form = $formWrapper.find('form');
			if ( !$form.length ) {
				// woocommerce product addons form
				// when it's a variable product the form is WC form, and the GF wrapper is inside that
				// when it's a simple product then the form is the GF form, inside the wrapper, but missing the original CSS classes added to it
				// see below
				$form = $formWrapper.closest('form');
			}

			if ( $form.length && ( !$form.attr('class') || $form.attr('class').indexOf('collapsible-sections') === -1 ) ) {
				// the form doesn't have the classes that were added to it pre-render
				// this happens with woocommerce product addons plugin
				// we'll use the wrapper classes to add them back
				var formClasses = [];
				$formWrapper.attr('class').split(' ').forEach(function(cls){
					if ( cls.trim() !== '' && ( cls.indexOf('cs-theme--') !== -1 || cls.indexOf('collapsible-sections') !== -1 ) ) {
						formClasses.push( cls.trim().replace('_wrapper', '') );
					}
				});
				$form.addClass( formClasses.join(' ') );
			}

			$collapsibleSections.each(function(i, el){
				var $section = $(this);
				var $sectionCollapsible = $section.next('.collapsible-sections-collapsible-body');
				if ( !$sectionCollapsible.length ) {
					return true;
				}

				if ( i === 0 ) {
					$section.addClass('collapsible-sections-start-field');
				}
				else if ( i === $collapsibleSections.length - 1 ) {
					// add the end class if not set
					$section.addClass('collapsible-sections-end-field');
				}

				var $upToNextCollapsibleSectionFields = $sectionCollapsible.nextUntil('.collapsible-sections-field');
				var $upToEndSectionFields = $sectionCollapsible.nextUntil('.collapsible-sections-end-field');

				var $sectionFields = ( $upToNextCollapsibleSectionFields.length > $upToEndSectionFields.length ) ? $upToEndSectionFields : $upToNextCollapsibleSectionFields;
				$sectionFields.detach();

				if ($section.hasClass('collapsible-sections-description-inside')) {
					var $description = $section.find('.gsection_description');
					if ($description.length) {
						$description.detach().appendTo($sectionCollapsible);
					}
				}

				$sectionCollapsible.append($sectionFields);

				$section.on('focus', collapsibleSections.onSectionFocus);
				$section.on('blur', collapsibleSections.onSectionBlur);
			});

			if ($formWrapper.hasClass('collapsible-sections-footer-inside_last_wrapper')) {
				var $lastSection = $formWrapper.find('.collapsible-sections-collapsible-body:last');
				$formWrapper.find('.gform_footer').detach().appendTo($lastSection);
			}

			if ($formWrapper.hasClass('collapsible-sections-single-opens_wrapper')) {
				$formWrapper.find('.collapsible-sections-field:not(.collapsible-sections-open):not(.collapsible-sections-end-field-hidden)').each(function(){
					var $section = $(this);
					$section.next('.collapsible-sections-collapsible-body').hide();
				});
			}

			var $fieldErrors = $formWrapper.find('.gfield_error');

			if ($fieldErrors.length) {

				var $form = $collapsibleSections.closest('form');
				var formIgnoreScroll = ( typeof $form.data('ignore-scroll') !== 'undefined' && $form.data('ignore-scroll') === true );

				$form.data('ignore-scroll', true);// force to ignore scroll
				var delay = (window.hasOwnProperty('gf_collapsible_sections_scroll_to_duration_'+gformID)) ? window['gf_collapsible_sections_scroll_to_duration_'+gformID] : 400;
				delay += 100;
				setTimeout(function(){
					$form.data('ignore-scroll', formIgnoreScroll);// reset to original setting
				}, delay);

				$fieldErrors.each(function(){
					var $errorSection = $(this).closest('.collapsible-sections-collapsible-body');
					$errorSection.prev('.collapsible-sections-field').addClass('collapsible-sections-section-error');
				});

				var $errorSections = $formWrapper.find('.collapsible-sections-section-error');
				var $currentlyOpenSections = $formWrapper.find('.collapsible-sections-open');

				if ($formWrapper.hasClass('collapsible-sections-single-opens_wrapper') && $errorSections.length) {
					if ($currentlyOpenSections.length && !$currentlyOpenSections.first().hasClass('collapsible-sections-section-error')) {
						// close the currently open section as it has no errors
						$currentlyOpenSections.first().trigger('click');
					}
					// open the first error section
					if ( !$errorSections.first().hasClass('collapsible-sections-open') ) {
						$errorSections.first().trigger('click');
					}
				}
				else if (!$formWrapper.hasClass('collapsible-sections-single-opens_wrapper') && $errorSections.length) {
					// open all error sections
					$currentlyOpenSections.each(function(){
						var $thisSection = $(this);
						if (!$thisSection.hasClass('collapsible-sections-section-error')) {
							$thisSection.trigger('click');
						}
					});
					$errorSections.each(function(){
						var $thisSection = $(this);
						if (!$thisSection.hasClass(collapsibleSections.cssClasses.open)) {
							$thisSection.trigger('click');
						}
					});
				}

			}

		}
	};

	collapsibleSections.refreshMCE = function(){
		if ( typeof tinymce === 'undefined' ) {
			return;
		}
		$('.collapsible-sections-collapsible-body textarea.wp-editor-area').each(function(){
			var editor = tinymce.get( $(this).attr('id') );
			if ( editor ) {
				var settings = $.extend({}, editor.settings);
				editor.remove();
				tinymce.init( settings );
			}
		});
	};

	var conditionalLogicListenerAdded = false;
	collapsibleSections.conditionalLogicListener = function() {

		if ( conditionalLogicListenerAdded ) {
			return;
		}

		$(document).on('gform_post_conditional_logic', function(e, formId, fields, isInit) {
			$('.gform_wrapper:not(.collapsible-sections-single-opens_wrapper) .collapsible-sections-field.collapsible-sections-open:hidden').each(function(){
				var $section = $(this);
				$section.removeClass(collapsibleSections.cssClasses.open);
				$section.next('.collapsible-sections-collapsible-body:visible').hide();
			});

			$('.collapsible-sections-open-by-default:visible').each(function(){
				var $section = $(this);
				if ( $section.data('collapsible-closed') !== true && $section.next('.collapsible-sections-collapsible-body').is(':hidden') ) {
					collapsibleSections.openAndFocusInput( $section );
				}
			});

			var $form = $('#gform_' + formId);
			var $signatureFields = $form.find( '.gfield_signature_container' );
			if ( $signatureFields.length && typeof gformSignatureResize === 'function' ) {
				$signatureFields.each( function() {
					var $this = $( this );
					setTimeout(function () { gformSignatureResize( $this ); }, 200 );
				});
			}

		});

		conditionalLogicListenerAdded = true;

	};

	collapsibleSections.getFormById = function( form_id ) {
		var $form = $('#gform_' + form_id);
		if ( !$form.length && $('.gform_wrapper[class*="_original_id_' + form_id + '"]').length )  {
			// using Gravity Forms: Multiple Form Instances Add On
			$form = $('.gform_wrapper[class*="_original_id_' + form_id + '"] form');
		}
		return $form;
	};

	collapsibleSections.init = function( form_id, current_page ) {

		var $forms = collapsibleSections.getFormById(form_id);
		if ( !$forms.length ) {
			return;
		}

		$forms.each(function(){

			var $form = $(this);
			var formDomId = $form.attr('id').replace('gform_', '');

			var $fieldsNeedingDelay = $('.gfield_signature_container');
			var setupDelay = ($fieldsNeedingDelay.length) ? 1000 : 10;
			if (typeof window.collapsibleSections_setupDelay !== 'undefined') {
				var setupDelayOverride = parseInt(window.collapsibleSections_setupDelay, 10);
				if (!isNaN(setupDelayOverride)) {
					setupDelay = setupDelayOverride * 1000;
				}
			}

			var formIgnoreScroll = (!window.hasOwnProperty('gf_collapsible_sections_scroll_to_'+form_id) || window['gf_collapsible_sections_scroll_to_'+form_id] === "false" || window['gf_collapsible_sections_scroll_to_'+form_id] === false);
			$form.data('ignore-scroll', formIgnoreScroll);

			setTimeout(function(){
				collapsibleSections.setup(formDomId, current_page, form_id);
				console.log('collapsibleSections.setup', formDomId);

				setTimeout(function(){
					collapsibleSections.refreshMCE();
					$(document).trigger('gform_post_conditional_logic', [form_id, null, true]);
					$(window).resize();
					collapsibleSections.conditionalLogicListener();
					$(document).trigger('gform_post_conditional_logic', [form_id, null, true]);

					$(document).trigger('gf_collapsible_sections_setup', [form_id, current_page, formDomId]);
					gform.doAction('gf_collapsible_sections_setup', form_id, current_page, formDomId);
				}, 100);
			}, setupDelay);

		});

	};

	var wcRechecks = {};
	$(document).bind('gform_post_render', function(event, form_id, current_page){

		var $forms = collapsibleSections.getFormById(form_id);
		if ( !$forms.length && !wcRechecks.hasOwnProperty(form_id.toString()) ) {
			var renderDelay = 3000;
			if (typeof window.collapsibleSections_renderDelay !== 'undefined') {
				var renderDelayOverride = parseInt(window.collapsibleSections_renderDelay, 10);
				if (!isNaN(renderDelayOverride)) {
					renderDelay = renderDelayOverride * 1000;
				}
			}
			console.log('renderDelay', renderDelay);
			wcRechecks[ form_id.toString() ] = setTimeout(function(){
				collapsibleSections.init(form_id, current_page);
			}, renderDelay);
		}
		else if ( $forms.length ) {
			collapsibleSections.init(form_id, current_page);
		}

	});

	$(document).ready(function(){

		$('body').on('click', '.collapsible-sections-field', function(e){
			e.preventDefault();
			var $section = $(this);
			var $formWrapper = $section.closest('.gform_wrapper');
			var $form = $formWrapper.find('form');
			if ( !$form.length ) {
				// probably woocommerce product addons form
				$form = $formWrapper.closest('form');
			}

			var gformID = $form.attr('id').replace('gform_', '');
			if ( $formWrapper.attr('class').indexOf('original_id_') !== -1 ) {
				// using Gravity Forms: Multiple Form Instances Add On
				var originalClass = $formWrapper.attr('class').split(" ")
					.filter(function(className){
						return ( className.indexOf("original_id_") !== -1 );
					})[0];
				gformID = originalClass.substring(originalClass.lastIndexOf('_') + 1);
			}

			var selector = $section.data('target');
			var $target = $formWrapper.find(selector);

			if ($target.length) {
				if ($target.is(':visible')) {
					$section.attr('aria-pressed', 'false').attr('aria-expanded', 'false');
					$section.data('collapsible-closed', true);
					$target.slideUp('fast', function(){
						$section.removeClass(collapsibleSections.cssClasses.open);
					});
				}
				else {
					if ($formWrapper.length && $formWrapper.hasClass('collapsible-sections-single-opens_wrapper')) {
						$formWrapper.find('.collapsible-sections-field:visible').not($section).each(function(){
							var $thisSection = $(this);
							var thisSelector = $thisSection.data('target');
							var $thisTarget = $formWrapper.find(thisSelector);
							if ($thisTarget.length) {
								$thisSection.attr('aria-pressed', 'false').attr('aria-expanded', 'false');
								$thisTarget.slideUp('fast', function(){
									$thisSection.removeClass(collapsibleSections.cssClasses.open);
								});
							}
						});
					}
					$section.addClass(collapsibleSections.cssClasses.open);
					$section.attr('aria-pressed', 'true').attr('aria-expanded', 'true');
					$section.data('collapsible-closed', false);
					$target.slideDown('fast', function(){
						$(document).trigger('gform_post_conditional_logic', [gformID, null, true]);
						$(window).resize();
						if ($formWrapper.hasClass('collapsible-sections-scrollto_wrapper')) {
							collapsibleSections.scrollTo($section);
						}
					});

				}
			}
		});

	});


})(jQuery);
